const excludedRoutes = [
  "/auth/signup",
  "/auth/login",
  "/",
  "/",
  "^(/.*)?$",
  "/category",
  "/banner",
  "/search-history",
  "^/admin(/.*)?$"
];

export const isExcludedRoute = (pathname: string) => {
  return excludedRoutes.some((routePattern) => {
    if (routePattern.startsWith("^")) {
      const regex = new RegExp(routePattern);
      return regex.test(pathname);
    }
    // It's a direct match string
    return pathname === routePattern;
  });
};
