import { FC, useState } from "react";
import { Button, Input } from "../../index";
import { RxMagnifyingGlass } from "react-icons/rx";

interface CategorySelectorProps {
  categories: { name: string; id: string; key?: string; }[];
  onSelect: (category: { name: string; id: string; key?: string; } | null) => void;
  selectedCategoryName?: string;
  searchPlaceholder?: string;
  categoryTitle: string;
}

export const CategorySelector: FC<CategorySelectorProps> = ({
  categories,
  onSelect,
  selectedCategoryName,
  searchPlaceholder = "Search",
  categoryTitle
}) => {
  const [filter, setFilter] = useState("");

  const handleSelect = (category: { name: string; id: string; key?: string; } | null) => {
    onSelect(category);
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(filter.toLowerCase()),
  );

  return (
    <div className="flex max-h-[375px] w-full flex-col gap-4 border border-fg-text-contrast px-2 py-6 max-w-72">
      <p className="text-center font-bold text-fg-text-contrast">
        Select {categoryTitle} Category
      </p>
      <div className="relative w-full px-4">
        <Input
          type="text"
          placeholder={searchPlaceholder}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="!rounded-full !text-xs lg:!text-base"
        />
        <RxMagnifyingGlass className="absolute right-8 top-[0.95rem] text-fg-text" />
      </div>
      <div className="flex flex-col gap-4 overflow-y-auto overflow-x-hidden px-4">
        {filteredCategories.map((category) => (
          <Button
            variant={
              selectedCategoryName === category.name ? "primary" : "gray"
            }
            type={selectedCategoryName === category.name ? "solid" : "outline"}
            size="medium"
            className="!h-fit w-full flex-shrink-0 !justify-start !px-[0.875rem] !py-[0.5rem] !text-start break-all"
            key={category.name}
            onClick={() => handleSelect(category)}
          >
            {category.key}. {category.name}
          </Button>
        ))}
      </div>
    </div>
  );
};
