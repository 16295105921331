import * as React from "react";
import cn from "classnames";

const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  React.ComponentProps<"textarea">
>(({ className, value, onChange, ...props }, ref) => {
  const spanRef = React.useRef<HTMLSpanElement>(null);
  const [height, setHeight] = React.useState("auto");

  // Adjust the height based on the span's content
  React.useEffect(() => {
    if (spanRef.current) {
      setHeight(`${spanRef.current.scrollHeight + 30}px`);
    }
  }, [value]);

  return (
    <div className="relative w-full">
      {/* Hidden span for height calculation */}
      <span
        ref={spanRef}
        className={cn(
          "invisible absolute top-0 left-0 w-full whitespace-pre-wrap overflow-hidden px-3 py-2 text-base",
          className
        )}
      >
        {value || props.placeholder}
      </span>
      {/* Textarea */}
      <textarea
        className={cn(
          "flex w-full rounded-md border border-input bg-transparent px-3 py-2 text-base shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
          className
        )}
        ref={ref}
        {...props}
        value={value}
        style={{ height }}
        onChange={onChange}
      />
    </div>
  );
});

Textarea.displayName = "Textarea";

export { Textarea };
