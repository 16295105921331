import { useLocation, useParams } from "react-router-dom";
import { CalculateNavbar } from "../../components/layout";
import { CalculationForm } from "../../components/sections";
import { useEffect, useMemo, useState } from "react";
import { useLazyGetCalculationQuery } from "../../redux/rtk/categoryQuery";
import { Loading } from "../../components/ui";

export const Calculate = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type: "courtFee" | "suitValuation" = (query.get("type") ||
    "courtFee") as "courtFee" | "suitValuation";
  const { id: calculationId } = useParams();
  const [calculationData, setCalculationData] = useState<any>(null);

  const [
    getCalculationTrigger,
    { data: calculationQueryData, isLoading: calculatedDataIsLoading },
  ] = useLazyGetCalculationQuery();

  const pageType = useMemo(() => calculationData?.type || type, [type, calculationData]);
  const pageTitle = useMemo(() => {
    const _type = calculationId ? calculationData?.type : type;
    switch (_type) {
      case "courtFee":
        return "Calculate Court Fee";
      case "suitValuation":
        return "Calculate Suit Valuation";
      default:
        return "";
    }
  }, [type, calculationData, calculationId]);

  useEffect(() => {
    if (calculationId) {
      getCalculationTrigger({
        id: calculationId,
      });
    } else {
      setCalculationData(null);
    }
  }, [calculationId]);

  useEffect(() => {
    setCalculationData(calculationQueryData);
  }, [calculationQueryData]);

  if (calculatedDataIsLoading || (calculationId && !calculationData))
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Loading />
      </div>
    );

  return (
    <>
      <CalculateNavbar pageTitle={pageTitle} />
      <section className="mx-auto flex h-full max-w-7xl px-4 py-12 lg:px-0">
        <CalculationForm
          pageType={pageType}
          key={calculationId}
          calculationId={calculationId}
          calculationData={calculationData}
        />
        <div className=""></div>
      </section>
    </>
  );
};
