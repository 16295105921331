import { Link, useNavigate } from "react-router-dom";
import globalData from "../../../dictionary/global.json";
import { Button } from "../../ui";
import { FC } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { FaChevronLeft } from "react-icons/fa6";
import { useMediaQuery } from "../../../lib/hooks";

interface CalculateNavbarProps {
  pageTitle?: string;
}

export const CalculateNavbar: FC<CalculateNavbarProps> = ({ pageTitle }) => {
  const targetPageType =
    pageTitle === "Calculate Suit Valuation" ? "courtFee" : "suitValuation";
  const targetPageTitle =
    pageTitle === "Calculate Suit Valuation"
      ? "Calculate Court Fee"
      : "Calculate Suit Valuation";
  const user = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const { width = 768 } = useMediaQuery();
  const buttonSize = width <= 768 ? "small" : "medium";

  return (
    <header className="px-4 py-3.5 md:h-24 md:py-0">
      <div className="mx-auto flex h-full max-w-7xl flex-col items-center justify-between gap-4 md:flex-row md:gap-0">
        <h1 className="text-lg font-bold text-fg-text-contrast md:text-3xl">
          <Button
            className="flex flex-row items-center gap-1 !bg-transparent !text-primary-text"
            onClick={() => navigate(-1)}
          >
            <FaChevronLeft className="text-lg text-fg-text-contrast" />
            <div className="text-nowrap text-lg font-bold md:text-3xl">
              {pageTitle}
            </div>
          </Button>
        </h1>
        <div className="flex items-center gap-6">
          <Link
            to={`?type=${targetPageType}`}
            className="flex h-8 items-center justify-center rounded-md border border-transparent bg-primary-solid px-3 text-center text-sm font-medium text-white transition duration-150 ease-in-out hover:bg-primary-solid-hover focus:outline-none active:outline-none md:h-11 md:px-3.5 md:text-input"
          >
            {targetPageTitle}
          </Link>
          <Link
            to={`/`}
            className="flex h-8 items-center justify-center rounded-md border border-transparent bg-primary-solid px-3 text-center text-sm font-medium text-white transition duration-150 ease-in-out hover:bg-primary-solid-hover focus:outline-none active:outline-none md:h-11 md:px-3.5 md:text-input"
          >
            Home
          </Link>
          <Button variant="primary" type="outline" size={buttonSize}>
            {globalData?.CALCULATE_NAVBAR?.BUTTONS_TEXT?.BUY_SEARCHES}
          </Button>
          <div className="hidden md:flex">
            <img
              alt="user"
              src={
                user?.profilePicture
                  ? user?.profilePicture
                  : "/assets/images/user-avatar.png"
              }
              className="hidden h-11 w-11 rounded-full md:block"
            />
          </div>
        </div>
        {/* <p className="mx-auto hidden w-full max-w-7xl justify-end pt-4 md:block">
        <Link to="/" className="text-sm text-primary-solid underline">
        {globalData?.CALCULATE_NAVBAR?.VIEW_PROFILE}
        </Link>
      </p> */}
      </div>
    </header>
  );
};
