import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../lib/utils/url";
import { Button, IconButton, Input } from "../../ui";
import { PiMinusCircle, PiPlusCircle } from "react-icons/pi";
import { Textarea } from "../../ui/textarea";

export interface MajorCategoryType {
  id: string;
  name: string;
  provinceId: string;
  type: "courtFee" | "suitValuation";
  index: number;
  minorCategory: MinorCategoryType[];
}

export interface MinorCategoryType {
  id: string;
  name: string;
  majorCategoryId: string;
  index: number;
  subminorCategory: SubminorCategoryType[];
}

export interface SuitValuationType {
  id: string;
  subminorCategoryId: string;
  type: "fixed" | "multiplier";
  userInput: number;
}

export interface SubminorCategoryType {
  id: string;
  index: number;
  name: string;
  minorCategoryId: string;
  activityByUser: string | number;
  courtFee: CourtFeeType[];
  suitValuation: SuitValuationType[];
  reference: string;
  minimumUserInput: number;
  maximumUserInput: number | null;
  extraResultInfo: string | null;
  defaultResult: string | null;
  defaultCourtFeeToBeAffixed: string | number | null;
}

export interface CourtFeeType {
  id: string;
  subminorCategoryId: string;
  type: "fixed" | "multiplier";
  minimumSuitValuation: number;
  maximumCourtFee: number;
  userInput: number;
  suitValuation: {
    type: "fixed" | "multiplier";
    userInput: number;
  };
}

interface AdminTableProps {
  provinceId: string;
}

export const CourtFeeTable: React.FC<AdminTableProps> = ({ provinceId }) => {
  const [categories, setCategories] = useState<MajorCategoryType[]>([]);

  const [majorCategoryInput, setMajorCategoryInput] = useState("");
  const [minorCategoryInput, setMinorCategoryInput] = useState<
    {
      majorCategoryId: string;
      name: string;
    }[]
  >([]);
  const [subminorCategoryInput, setSubminorCategoryInput] = useState<
    {
      minorCategoryId: string;
      name: string;
    }[]
  >([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get<MajorCategoryType[]>(
          `${BASE_URL}/v1/category-data/get-categories-data/${provinceId}/courtFee`,
        );
        setCategories(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCategories();
  }, [provinceId]);

  const handleAddMajorCategory = () => {
    const newCategory: MajorCategoryType = {
      id: new Date().toISOString(),
      name: majorCategoryInput,
      provinceId: provinceId,
      type: "courtFee",
      minorCategory: [],
      index: categories.length + 1,
    };
    setCategories([...categories, newCategory]);
    setMajorCategoryInput("");
  };

  const handleAddMinorCategory = (majorCategoryId: string) => {
    const minorCategoryInputData = minorCategoryInput.find(
      (mc) => mc.majorCategoryId === majorCategoryId,
    )?.name;
    const newMinorCategory: MinorCategoryType = {
      id: new Date().toISOString(),
      name: minorCategoryInputData || "",
      majorCategoryId: majorCategoryId,
      subminorCategory: [],
      index: (categories.find((c) => c.id === majorCategoryId)?.minorCategory?.length || 0) + 1,
    };
    const newCategories = categories.map((category) => {
      if (category.id === majorCategoryId) {
        return {
          ...category,
          minorCategory: [...category.minorCategory, newMinorCategory],
        };
      }
      return category;
    });
    setCategories(newCategories);
    const updatedMinorCategoryInput = minorCategoryInput.map((mc) => {
      if (mc.majorCategoryId === majorCategoryId) {
        return { ...mc, name: "" };
      }
      return mc;
    });
    setMinorCategoryInput(updatedMinorCategoryInput);
  };

  const handleAddSubminorCategory = (minorCategoryId: string) => {
    const subminorCategoryInputData = subminorCategoryInput.find(
      (sc) => sc.minorCategoryId === minorCategoryId,
    );
    const subMinorCategoryId = new Date().toISOString();
    const newSubminorCategory: SubminorCategoryType = {
      id: subMinorCategoryId,
      index: (categories.find((c) =>
        c.minorCategory.find((mc) => mc.id === minorCategoryId),
      )?.minorCategory.find((mc) => mc.id === minorCategoryId)?.subminorCategory.length || 0) + 1,
      name: subminorCategoryInputData?.name || "",
      minorCategoryId: minorCategoryId,
      activityByUser: "",
      courtFee: [
        {
          id: new Date().toISOString(),
          subminorCategoryId: subMinorCategoryId,
          type: "fixed",
          minimumSuitValuation: 0,
          maximumCourtFee: 0,
          userInput: 0,
          suitValuation: {
            type: "fixed",
            userInput: 0,
          },
        },
      ],
      minimumUserInput: 0,
      maximumUserInput: null,
      extraResultInfo: null,
      defaultResult: null,
      defaultCourtFeeToBeAffixed: null,
      suitValuation: [],
      reference: "",
    };
    const newCategories = categories.map((category) => {
      return {
        ...category,
        minorCategory: category.minorCategory.map((minorCategory) => {
          if (minorCategory.id === minorCategoryId) {
            return {
              ...minorCategory,
              subminorCategory: [
                ...minorCategory.subminorCategory,
                newSubminorCategory,
              ],
            };
          }
          return minorCategory;
        }),
      };
    });
    setCategories(newCategories);
    const updatedSubminorCategoryInput = subminorCategoryInput.map((sc) => {
      if (sc.minorCategoryId === minorCategoryId) {
        return { ...sc, name: "" };
      }
      return sc;
    });
    setSubminorCategoryInput(updatedSubminorCategoryInput);
  };

  const handleDelete = async (id: string, categoryType: string) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/v1/category-data/delete-category/${categoryType}/${id}`,
      );
      const res = await axios.get<MajorCategoryType[]>(
        `${BASE_URL}/v1/category-data/get-categories-data/${provinceId}/courtFee`,
      );
      setCategories(res.data);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const handleSave = async () => {
    try {
      const updatedData = await axios.post<MajorCategoryType[]>(
        `${BASE_URL}/v1/category-data/add-categories-data/${provinceId}/courtFee`,
        {
          majorCategories: categories,
        },
      );
      setCategories(updatedData.data);
      alert("Data saved successfully!");
    } catch (error) {
      console.error(error);
      alert("Failed to save data.");
    }
  };

  return (
    <div className="flex flex-col gap-4 overflow-hidden max-h-[80vh]">
      <Button
        variant="primary"
        size="small"
        type="solid"
        onClick={handleSave}
        className="self-start"
      >
        {" "}
        Save{" "}
      </Button>
      <div className="flex flex-col overflow-auto flex-1">
        <table className="w-full table-auto overflow-auto text-left rtl:text-right">
          <thead className="w-full table-auto overflow-auto text-left rtl:text-right">
            <tr className="h-10">
              <th className="w-60 min-w-60 rounded-tl-lg bg-bg-bg-hover p-2 text-sm font-semibold">
                Major Category
              </th>
              <th className="w-60 min-w-60 bg-bg-bg-hover p-2 text-sm font-semibold">
                Minor Category
              </th>
              <th className="w-60 min-w-60 bg-bg-bg-hover p-2 text-sm font-semibold">
                Subminor Category
              </th>
              <th className="w-40 min-w-40 bg-bg-bg-hover p-2 text-sm font-semibold">
                Activity By User
              </th>
              <th className="w-40 min-w-40 bg-bg-bg-hover p-2 text-sm font-semibold">
                Min SV User Input
              </th>
              <th className="w-40 min-w-40 bg-bg-bg-hover p-2 text-sm font-semibold">
                Max SV User Input
              </th>
              <th className="w-40 min-w-40 bg-bg-bg-hover p-2 text-sm font-semibold">
                SV Type
              </th>
              <th className="w-40 min-w-40 bg-bg-bg-hover p-2 text-sm font-semibold">
                SV User Input
              </th>
              <th className="w-40 min-w-40 bg-bg-bg-hover p-2 text-sm font-semibold">
                CF Type
              </th>
              <th className="w-40 min-w-40 bg-bg-bg-hover p-2 text-sm font-semibold">
                CF User Input
              </th>
              <th className="w-40 min-w-40 bg-bg-bg-hover p-2 text-sm font-semibold">
                Default Result
              </th>
              <th className="w-40 min-w-40 bg-bg-bg-hover p-2 text-sm font-semibold">
                Extra Result Info
              </th>
              <th className="w-40 min-w-40 bg-bg-bg-hover p-2 text-sm font-semibold">
                Default CF To Be Affixed
              </th>
              <th className="w-40 min-w-40 bg-bg-bg-hover p-2 text-sm font-semibold">
                Min SV
              </th>
              <th className="w-40 min-w-40 bg-bg-bg-hover p-2 text-sm font-semibold">
                Max CF
              </th>
              <th className="w-40 min-w-40 rounded-tr-lg bg-bg-bg-hover p-2 text-sm font-semibold">
                Reference
              </th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <React.Fragment key={category.id}>
                <tr className="border-b">
                  <td colSpan={1} className="flex items-center gap-1.5">
                    <span className="p-2">{category.index}.</span>
                    <Textarea
                      placeholder="Add major category"
                      value={category.name}
                      onChange={(e) => {
                        let newCategories = [...categories];
                        newCategories = newCategories.map((mc) => {
                          if (mc.id === category.id) {
                            return { ...mc, name: e.target.value };
                          }
                          return mc;
                        });
                        setCategories(newCategories);
                      }}
                      className="!text-sm"
                    />
                    <IconButton
                      variant="primary"
                      size="small"
                      type="soft"
                      icon={<PiMinusCircle />}
                      className="!bg-alert-solid text-bg-default"
                      onClick={() =>
                        handleDelete(category.id, "majorCategory")
                      }
                    />
                  </td>
                </tr>
                {category.minorCategory.map((minorCategory) => (
                  <React.Fragment key={minorCategory.id}>
                    <tr className="border-b">
                      <td colSpan={1} className="bg-transparent"></td>
                      <td colSpan={1} className="flex items-center gap-1.5">
                        <span className="p-2">{minorCategory.index}.</span>
                        <Textarea
                          placeholder="Add minor category"
                          value={minorCategory.name}
                          onChange={(e) => {
                            const newCategories = [...categories];
                            const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                            newCategories[categoryIndex].minorCategory =
                              newCategories[categoryIndex].minorCategory.map((mc) => {
                                if (mc.id === minorCategory.id) {
                                  return { ...mc, name: e.target.value };
                                }
                                return mc;
                              });
                            setCategories(newCategories);
                          }}
                          className="!text-sm"
                        />
                        <IconButton
                          variant="primary"
                          size="small"
                          type="soft"
                          icon={<PiMinusCircle />}
                          className="!bg-alert-solid text-bg-default"
                          onClick={() =>
                            handleDelete(minorCategory.id, "minorCategory")
                          }
                        />
                      </td>
                    </tr>
                    {minorCategory.subminorCategory.map(
                      (subminorCategory) => (
                        <tr key={subminorCategory.id} className="border-b">
                          <td colSpan={2} className="bg-transparent"></td>
                          <td colSpan={1} className="bg-transparent">
                            <div className="flex items-center gap-1.5">
                              <span className="p-2">{subminorCategory.index}.</span>
                              <Textarea
                                className="!text-sm"
                                placeholder="Add subminor category"
                                value={subminorCategory.name}
                                onChange={(e) => {
                                  const newCategories = [...categories];
                                  const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                  newCategories[categoryIndex].minorCategory =
                                    newCategories[categoryIndex].minorCategory.map(
                                      (mc) => {
                                        if (mc.id === minorCategory.id) {
                                          return {
                                            ...mc,
                                            subminorCategory:
                                              mc.subminorCategory.map(
                                                (sc) => {
                                                  if (
                                                    sc.id ===
                                                    subminorCategory.id
                                                  ) {
                                                    return {
                                                      ...sc,
                                                      name: e.target.value,
                                                    };
                                                  }
                                                  return sc;
                                                },
                                              ),
                                          };
                                        }
                                        return mc;
                                      },
                                    );
                                  setCategories(newCategories);
                                }}
                              />
                              <IconButton
                                variant="primary"
                                size="small"
                                type="soft"
                                icon={<PiMinusCircle />}
                                className="!bg-alert-solid text-bg-default"
                                onClick={() =>
                                  handleDelete(
                                    subminorCategory.id,
                                    "subminorCategory",
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td colSpan={1} className="w-56">
                            <Textarea
                              className="!text-sm"
                              placeholder="Activity By User"
                              value={subminorCategory.activityByUser}
                              onChange={(e) => {
                                const newCategories = [...categories];
                                const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                newCategories[categoryIndex].minorCategory =
                                  newCategories[categoryIndex].minorCategory.map(
                                    (mc) => {
                                      if (mc.id === minorCategory.id) {
                                        return {
                                          ...mc,
                                          subminorCategory:
                                            mc.subminorCategory.map((sc) => {
                                              if (
                                                sc.id === subminorCategory.id
                                              ) {
                                                let activityByUser: string | number = e.target.value;
                                                if (typeof activityByUser === "string" && !isNaN(Number(activityByUser))) {
                                                  if (!activityByUser.endsWith(".")) {
                                                    activityByUser = Number(activityByUser); // Reassign as a number
                                                  }
                                                }                                                
                                                return {
                                                  ...sc,
                                                  activityByUser
                                                };
                                              }
                                              return sc;
                                            }),
                                        };
                                      }
                                      return mc;
                                    },
                                  );
                                setCategories(newCategories);
                              }}
                            />
                          </td>
                          <td colSpan={1} className="w-56">
                            <Input
                              type="number"
                              min={0}
                              className="!h-9 !text-sm"
                              placeholder="Min SV User Input"
                              value={subminorCategory.minimumUserInput || 0}
                              onChange={(e) => {
                                const newCategories = [...categories];
                                const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                newCategories[categoryIndex].minorCategory =
                                  newCategories[categoryIndex].minorCategory.map(
                                    (mc) => {
                                      if (mc.id === minorCategory.id) {
                                        return {
                                          ...mc,
                                          subminorCategory:
                                            mc.subminorCategory.map((sc) => {
                                              if (
                                                sc.id === subminorCategory.id
                                              ) {
                                                return {
                                                  ...sc,
                                                  minimumUserInput: e.target
                                                    .value?.length
                                                    ? Number(e.target.value)
                                                    : 0,
                                                };
                                              }
                                              return sc;
                                            }),
                                        };
                                      }
                                      return mc;
                                    },
                                  );
                                setCategories(newCategories);
                              }}
                            />
                          </td>
                          <td colSpan={1} className="w-56">
                            <Input
                              type="number"
                              min={0}
                              className="!h-9 !text-sm"
                              value={subminorCategory.maximumUserInput || ""}
                              placeholder="Max SV User Input"
                              onChange={(e) => {
                                const newCategories = [...categories];
                                const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                newCategories[categoryIndex].minorCategory =
                                  newCategories[categoryIndex].minorCategory.map(
                                    (mc) => {
                                      if (mc.id === minorCategory.id) {
                                        return {
                                          ...mc,
                                          subminorCategory:
                                            mc.subminorCategory.map((sc) => {
                                              if (
                                                sc.id === subminorCategory.id
                                              ) {
                                                return {
                                                  ...sc,
                                                  maximumUserInput: e.target
                                                    .value?.length
                                                    ? Number(e.target.value)
                                                    : null,
                                                };
                                              }
                                              return sc;
                                            }),
                                        };
                                      }
                                      return mc;
                                    },
                                  );
                                setCategories(newCategories);
                              }}
                            />
                          </td>
                          <td colSpan={1} className="">
                            {/* Create Selector to select from either multiplier or fixed */}
                            <select
                              className="h-9 w-full rounded-md border border-secondary-solid bg-transparent px-3.5 text-sm font-medium text-fg-text-contrast outline-none placeholder:text-secondary-solid focus:border-fg-border"
                              value={
                                subminorCategory.courtFee[0]?.suitValuation
                                  ?.type
                              }
                              onChange={(e) => {
                                const newCategories = [...categories];
                                const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                newCategories[categoryIndex].minorCategory =
                                  newCategories[categoryIndex].minorCategory.map(
                                    (mc) => {
                                      if (mc.id === minorCategory.id) {
                                        return {
                                          ...mc,
                                          subminorCategory:
                                            mc.subminorCategory.map((sc) => {
                                              if (
                                                sc.id === subminorCategory.id
                                              ) {
                                                return {
                                                  ...sc,
                                                  courtFee: sc.courtFee.map(
                                                    (cf) => {
                                                      return {
                                                        ...cf,
                                                        suitValuation: {
                                                          ...cf.suitValuation,
                                                          type: e.target
                                                            .value as
                                                            | "fixed"
                                                            | "multiplier",
                                                        },
                                                      };
                                                    },
                                                  ),
                                                };
                                              }
                                              return sc;
                                            }),
                                        };
                                      }
                                      return mc;
                                    },
                                  );

                                console.log('New Categories', newCategories)
                                setCategories(newCategories);
                              }}
                            >
                              <option value="fixed">Fixed</option>
                              <option value="multiplier">Multiplier</option>
                            </select>
                          </td>
                          <td colSpan={1} className="bg-gray-50">
                            <Input
                              type="number"
                              placeholder="SV User Input"
                              min={0}
                              className="!h-9 !text-sm"
                              value={
                                subminorCategory.courtFee[0]?.suitValuation
                                  ?.userInput
                              }
                              onChange={(e) => {
                                const newCategories = [...categories];
                                const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                newCategories[categoryIndex].minorCategory =
                                  newCategories[categoryIndex].minorCategory.map(
                                    (mc) => {
                                      if (mc.id === minorCategory.id) {
                                        return {
                                          ...mc,
                                          subminorCategory:
                                            mc.subminorCategory.map((sc) => {
                                              if (
                                                sc.id === subminorCategory.id
                                              ) {
                                                return {
                                                  ...sc,
                                                  courtFee: sc.courtFee.map(
                                                    (cf) => {
                                                      return {
                                                        ...cf,
                                                        suitValuation: {
                                                          ...cf.suitValuation,
                                                          userInput: Number(
                                                            e.target.value,
                                                          ),
                                                        },
                                                      };
                                                    },
                                                  ),
                                                };
                                              }
                                              return sc;
                                            }),
                                        };
                                      }
                                      return mc;
                                    },
                                  );
                                setCategories(newCategories);
                              }}
                            />
                          </td>
                          <td colSpan={1} className="bg-gray-50">
                            {/* Create Selector to select from either multiplier or fixed */}
                            <select
                              className="h-9 w-full rounded-md border border-secondary-solid bg-transparent px-3.5 text-sm font-medium text-fg-text-contrast outline-none placeholder:text-secondary-solid focus:border-fg-border"
                              value={subminorCategory.courtFee[0]?.type}
                              onChange={(e) => {
                                const newCategories = [...categories];
                                const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                newCategories[categoryIndex].minorCategory =
                                  newCategories[categoryIndex].minorCategory.map(
                                    (mc) => {
                                      if (mc.id === minorCategory.id) {
                                        return {
                                          ...mc,
                                          subminorCategory:
                                            mc.subminorCategory.map((sc) => {
                                              if (
                                                sc.id === subminorCategory.id
                                              ) {
                                                return {
                                                  ...sc,
                                                  courtFee: sc.courtFee.map(
                                                    (cf) => {
                                                      return {
                                                        ...cf,
                                                        type: e.target
                                                          .value as
                                                          | "fixed"
                                                          | "multiplier",
                                                      };
                                                    },
                                                  ),
                                                };
                                              }
                                              return sc;
                                            }),
                                        };
                                      }
                                      return mc;
                                    },
                                  );
                                setCategories(newCategories);
                              }}
                            >
                              <option value="fixed">Fixed</option>
                              <option value="multiplier">Multiplier</option>
                            </select>
                          </td>
                          <td colSpan={1} className="bg-gray-50">
                            <Input
                              type="number"
                              min={0}
                              placeholder="CF User Input"
                              className="!h-9 w-full !text-sm"
                              value={subminorCategory.courtFee[0]?.userInput}
                              onChange={(e) => {
                                const newCategories = [...categories];
                                const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                newCategories[categoryIndex].minorCategory =
                                  newCategories[categoryIndex].minorCategory.map(
                                    (mc) => {
                                      if (mc.id === minorCategory.id) {
                                        return {
                                          ...mc,
                                          subminorCategory:
                                            mc.subminorCategory.map((sc) => {
                                              if (
                                                sc.id === subminorCategory.id
                                              ) {
                                                return {
                                                  ...sc,
                                                  courtFee: sc.courtFee.map(
                                                    (cf) => {
                                                      return {
                                                        ...cf,
                                                        userInput: Number(
                                                          e.target.value,
                                                        ),
                                                      };
                                                    },
                                                  ),
                                                };
                                              }
                                              return sc;
                                            }),
                                        };
                                      }
                                      return mc;
                                    },
                                  );
                                setCategories(newCategories);
                              }}
                            />
                          </td>
                          <td colSpan={1} className="bg-gray-50">
                            <Textarea
                              className="!text-sm"
                              placeholder="Default Result"
                              value={subminorCategory.defaultResult || ""}
                              onChange={(e) => {
                                const newCategories = [...categories];
                                const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                newCategories[categoryIndex].minorCategory =
                                  newCategories[categoryIndex].minorCategory.map(
                                    (mc) => {
                                      if (mc.id === minorCategory.id) {
                                        return {
                                          ...mc,
                                          subminorCategory:
                                            mc.subminorCategory.map((sc) => {
                                              if (
                                                sc.id === subminorCategory.id
                                              ) {
                                                return {
                                                  ...sc,
                                                  defaultResult: e.target
                                                    .value?.length
                                                    ? e.target.value
                                                    : null,
                                                };
                                              }
                                              return sc;
                                            }),
                                        };
                                      }
                                      return mc;
                                    },
                                  );
                                setCategories(newCategories);
                              }}
                            />
                          </td>
                          <td colSpan={1} className="bg-gray-50">
                            <Textarea
                              className="!text-sm"
                              placeholder="Extra Result Info"
                              value={subminorCategory.extraResultInfo || ""}
                              onChange={(e) => {
                                const newCategories = [...categories];
                                const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                newCategories[categoryIndex].minorCategory =
                                  newCategories[categoryIndex].minorCategory.map(
                                    (mc) => {
                                      if (mc.id === minorCategory.id) {
                                        return {
                                          ...mc,
                                          subminorCategory:
                                            mc.subminorCategory.map((sc) => {
                                              if (
                                                sc.id === subminorCategory.id
                                              ) {
                                                return {
                                                  ...sc,
                                                  extraResultInfo: e.target
                                                    .value?.length
                                                    ? e.target.value
                                                    : null,
                                                };
                                              }
                                              return sc;
                                            }),
                                        };
                                      }
                                      return mc;
                                    },
                                  );
                                setCategories(newCategories);
                              }}
                            />
                          </td>
                          <td colSpan={1} className="bg-gray-50">
                            <Input
                              type="text"
                              min={0}
                              className="!h-9 !text-sm"
                              placeholder="Default CF To Be Affixed"
                              value={
                                subminorCategory.defaultCourtFeeToBeAffixed ||
                                ""
                              }
                              onChange={(e) => {
                                const newCategories = [...categories];
                                const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                newCategories[categoryIndex].minorCategory =
                                  newCategories[categoryIndex].minorCategory.map(
                                    (mc) => {
                                      if (mc.id === minorCategory.id) {
                                        return {
                                          ...mc,
                                          subminorCategory:
                                            mc.subminorCategory.map((sc) => {
                                              if (
                                                sc.id === subminorCategory.id
                                              ) {
                                                let defaultCourtFeeToBeAffixed: string | number = e.target.value;
                                                if (!isNaN(Number(defaultCourtFeeToBeAffixed))) {
                                                  defaultCourtFeeToBeAffixed = Number(defaultCourtFeeToBeAffixed);
                                                }
                                                return {
                                                  ...sc,
                                                  defaultCourtFeeToBeAffixed: typeof defaultCourtFeeToBeAffixed === 'string' ? defaultCourtFeeToBeAffixed.length ? defaultCourtFeeToBeAffixed : null : defaultCourtFeeToBeAffixed
                                                };
                                              }
                                              return sc;
                                            }),
                                        };
                                      }
                                      return mc;
                                    },
                                  );
                                setCategories(newCategories);
                              }}
                            />
                          </td>
                          <td colSpan={1} className="bg-gray-50">
                            <Input
                              type="number"
                              min={0}
                              className="!h-9 !text-sm"
                              placeholder="Min SV"
                              value={
                                subminorCategory.courtFee[0]
                                  ?.minimumSuitValuation
                              }
                              onChange={(e) => {
                                const newCategories = [...categories];
                                const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                newCategories[categoryIndex].minorCategory =
                                  newCategories[categoryIndex].minorCategory.map(
                                    (mc) => {
                                      if (mc.id === minorCategory.id) {
                                        return {
                                          ...mc,
                                          subminorCategory:
                                            mc.subminorCategory.map((sc) => {
                                              if (
                                                sc.id === subminorCategory.id
                                              ) {
                                                return {
                                                  ...sc,
                                                  courtFee: sc.courtFee.map(
                                                    (cf) => {
                                                      return {
                                                        ...cf,
                                                        minimumSuitValuation:
                                                          Number(
                                                            e.target.value,
                                                          ),
                                                      };
                                                    },
                                                  ),
                                                };
                                              }
                                              return sc;
                                            }),
                                        };
                                      }
                                      return mc;
                                    },
                                  );
                                setCategories(newCategories);
                              }}
                            />
                          </td>
                          <td colSpan={1} className="bg-gray-50">
                            <Input
                              type="number"
                              min={0}
                              className="!h-9 !text-sm"
                              placeholder="Max CF"
                              value={
                                subminorCategory.courtFee[0]?.maximumCourtFee
                              }
                              onChange={(e) => {
                                const newCategories = [...categories];
                                const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                newCategories[categoryIndex].minorCategory =
                                  newCategories[categoryIndex].minorCategory.map(
                                    (mc) => {
                                      if (mc.id === minorCategory.id) {
                                        return {
                                          ...mc,
                                          subminorCategory:
                                            mc.subminorCategory.map((sc) => {
                                              if (
                                                sc.id === subminorCategory.id
                                              ) {
                                                return {
                                                  ...sc,
                                                  courtFee: sc.courtFee.map(
                                                    (cf) => {
                                                      return {
                                                        ...cf,
                                                        maximumCourtFee:
                                                          Number(
                                                            e.target.value,
                                                          ),
                                                      };
                                                    },
                                                  ),
                                                };
                                              }
                                              return sc;
                                            }),
                                        };
                                      }
                                      return mc;
                                    },
                                  );
                                setCategories(newCategories);
                              }}
                            />
                          </td>
                          <td colSpan={1} className="bg-gray-50">
                            <Textarea
                              className="!text-sm"
                              placeholder="Reference"
                              value={subminorCategory.reference}
                              onChange={(e) => {
                                const newCategories = [...categories];
                                const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
                                newCategories[categoryIndex].minorCategory =
                                  newCategories[categoryIndex].minorCategory.map(
                                    (mc) => {
                                      if (mc.id === minorCategory.id) {
                                        return {
                                          ...mc,
                                          subminorCategory:
                                            mc.subminorCategory.map((sc) => {
                                              if (
                                                sc.id === subminorCategory.id
                                              ) {
                                                return {
                                                  ...sc,
                                                  reference: e.target.value,
                                                };
                                              }
                                              return sc;
                                            }),
                                        };
                                      }
                                      return mc;
                                    },
                                  );
                                setCategories(newCategories);
                              }}
                            />
                          </td>
                        </tr>
                      ),
                    )}
                    <tr className="border-b">
                      <td colSpan={2} className="bg-transparent"></td>
                      <td
                        colSpan={9}
                        className="flex items-center justify-center gap-1.5"
                      >
                        <Input
                          type="text"
                          className="!h-9 !text-sm"
                          placeholder="Add subminor category"
                          value={
                            subminorCategoryInput.find(
                              (m) => m.minorCategoryId === minorCategory.id,
                            )?.name || ""
                          }
                          onChange={(e) => {
                            const subminorCategoryInputData =
                              subminorCategoryInput.find(
                                (m) => m.minorCategoryId === minorCategory.id,
                              );
                            if (!subminorCategoryInputData) {
                              setSubminorCategoryInput([
                                ...subminorCategoryInput,
                                {
                                  minorCategoryId: minorCategory.id,
                                  name: e.target.value,
                                },
                              ]);
                              return;
                            }
                            const newSubminorCategoryInput =
                              subminorCategoryInput.map((sc) => {
                                if (sc.minorCategoryId === minorCategory.id) {
                                  return { ...sc, name: e.target.value };
                                }
                                return sc;
                              });
                            setSubminorCategoryInput(
                              newSubminorCategoryInput,
                            );
                          }}
                        />
                        <IconButton
                          variant="primary"
                          size="small"
                          type="soft"
                          icon={<PiPlusCircle />}
                          className="!bg-success-solid text-bg-default"
                          onClick={() =>
                            handleAddSubminorCategory(minorCategory.id)
                          }
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
                <tr className="border-b">
                  {/* 2 Cells One occupy first header second occupy all remaining headers */}
                  <td colSpan={1} className="bg-transparent"></td>
                  <td
                    colSpan={10}
                    className="flex w-60 items-center justify-center gap-1.5"
                  >
                    <Input
                      type="text"
                      className="!h-9 !text-sm"
                      placeholder="Add minor category"
                      value={
                        minorCategoryInput.find(
                          (m) => m.majorCategoryId === category.id,
                        )?.name || ""
                      }
                      onChange={(e) => {
                        const minorCategoryInputData = minorCategoryInput.find(
                          (m) => m.majorCategoryId === category.id,
                        );
                        if (!minorCategoryInputData) {
                          setMinorCategoryInput([
                            ...minorCategoryInput,
                            {
                              majorCategoryId: category.id,
                              name: e.target.value,
                            },
                          ]);
                          return;
                        }
                        const newMinorCategoryInput = minorCategoryInput.map(
                          (mc) => {
                            if (mc.majorCategoryId === category.id) {
                              return { ...mc, name: e.target.value };
                            }
                            return mc;
                          },
                        );
                        setMinorCategoryInput(newMinorCategoryInput);
                      }}
                    />
                    <IconButton
                      variant="primary"
                      size="small"
                      type="soft"
                      icon={<PiPlusCircle />}
                      className="!bg-success-solid text-bg-default"
                      onClick={() => handleAddMinorCategory(category.id)}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
            <tr className="border-b">
              <td
                colSpan={11}
                className="flex w-60 items-center justify-center gap-1.5"
              >
                <Input
                  type="text"
                  className="!h-9 !text-sm"
                  placeholder="Add major category"
                  value={majorCategoryInput}
                  onChange={(e) => {
                    setMajorCategoryInput(e.target.value);
                  }}
                />
                <IconButton
                  variant="primary"
                  size="small"
                  type="soft"
                  icon={<PiPlusCircle />}
                  className="!bg-success-solid text-bg-default"
                  onClick={handleAddMajorCategory}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  );
};
