import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FC, useEffect } from "react";
import { loginWithProvider, refreshTokens } from "../../redux/slice/authSlice";
import { useAppDispatch } from "../../redux/hooks";
import { isTokenExpired } from "../../lib/utils/token";
import Unauthorized from "../../pages/unauthorized";
import { Loading } from "../ui";

export const ProtectedRoute: FC<{
  allowedRoles: string[];
  initialized: boolean;
}> = ({ allowedRoles, initialized }) => {
  const { isAuthenticated, tokens, initialTokenRefeshing, user, loading, googleProviderLoginStarted, googleProviderLoginFinished } = useSelector(
    (state: RootState) => state.auth,
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const pid = searchParams.get("pid");
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Pid Recieved', pid)
    if (pid) {
      dispatch(loginWithProvider(pid));
    }
  }, []);

  useEffect(() => {
    if (tokens?.access && isTokenExpired(tokens.access.expires)) {
      dispatch(refreshTokens(tokens?.refresh));
    }
  }, [tokens, isAuthenticated]);

  useEffect(() => {
    if (googleProviderLoginStarted && googleProviderLoginFinished) {
      navigate('/');
    }
  }, [googleProviderLoginStarted, googleProviderLoginFinished])

  useEffect(() => {
    if (tokens?.access && isTokenExpired(tokens.access.expires)) {
      dispatch(refreshTokens(tokens.refresh));
    }
  }, [tokens]);

  if (!initialized && loading && initialTokenRefeshing) {
    return <div className="w-screen h-screen flex justify-center items-center"><Loading /></div>
  }

  return (isAuthenticated && (tokens?.refresh?.token?.length || 0) > 0) ? user?.role && allowedRoles.includes(user?.role) ? (
    <Outlet />
  ) : <Unauthorized /> : (
    <Navigate to="/auth/login" state={{ from: location }} replace />
  );
};
