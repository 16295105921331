import { useEffect, useState } from "react";
import { Button, IconButton } from "../../ui";
import { SelectCountryDialog } from "../select-country-dialog";
import { SelectProvinceDialog } from "../select-province-dialog";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { AddSlabToProvince, fetchCountries, provinceById, RemoveSlabFromProvince, selectSlab } from "../../../redux/slice/dashboardSlice";
import { MdCancel } from "react-icons/md";
import { SelectSlabDialogue } from "../select-slab";
import { Slab } from "../../../types";

interface HeaderProps {
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  setProvince: React.Dispatch<React.SetStateAction<string>>;
  setProvinceId: React.Dispatch<React.SetStateAction<string>>;
  provinceId: string;
  showButton: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  setCountry,
  setProvince,
  setProvinceId,
  provinceId,
  showButton
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { countries, provinces } = useSelector((state: RootState) => state.dashboard);
  const [isSlabDialogOpen, setIsSlabDialogOpen] = useState(false);
  const [isCountryDialogOpen, setIsCountryDialogOpen] = useState(false);
  const [isProvinceDialogOpen, setIsProvinceDialogOpen] = useState(false);

  const [selectedSlab, setSelectedSlab] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");

  const handleCountrySelected = (country: string) => {
    setSelectedCountry(country);
    setCountry(country);
    setProvince("");
    setProvinceId("");
    setSelectedProvince("")
    setIsCountryDialogOpen(false);
  };

  const handleSlabSelected = (slab: Slab) => {
    if (provinceId === "") {
      return
    }
    dispatch(AddSlabToProvince({ slabId: slab.id || "", provinceId })).unwrap().then((response) => {
      if (response.province.slabId) {
        dispatch(selectSlab(response.province.slabId)).unwrap().then((slab) => { setSelectedSlab(slab.name); })
          .catch((error) => {
            console.error("Failed:fetching slab information", error);
            setSelectedSlab('')
          });
      } else {
        setSelectedSlab('')
      }

      setIsSlabDialogOpen(false);
    }).catch((error) => {
      console.error('Failed to add slab to province:', error);
    });
  };

  const handleSlabRemove = () => {
    if (provinceId === "") {
      return
    }
    dispatch(RemoveSlabFromProvince({ provinceId })).unwrap().then((response) => setSelectedSlab(response.province.slabId));
  }

  const updateProvinceId = async (provinceName: string) => {
    const provincesArray = Array.isArray(provinces) ? provinces : [];
    const selectedProvinceObj = provincesArray.find((province) => province.name === provinceName);
    if (selectedProvinceObj?.id) {
      setProvinceId(selectedProvinceObj.id);
    }
  }

  const handleProvinceSelected = (province: string) => {
    setSelectedProvince(province);
    setProvince(province);
    for (const country of countries) {
      if (country.provinces) {
        for (const listed_province of country.provinces) {
          if (listed_province.name.toLowerCase() === province.toLowerCase()) {
            setProvinceId(listed_province.id as string);
          }
        }
      }
    }
    updateProvinceId(province);
    setIsProvinceDialogOpen(false);
  };

  useEffect(() => {
    if (provinceId) {
      dispatch(provinceById(provinceId))
        .unwrap()
        .then((response) => {
          if (response.slabId) {
            dispatch(selectSlab(response.slabId)).unwrap().then((slab) => { setSelectedSlab(slab.name); })
              .catch((error) => {
                console.error("Failed to fetch slab information:", error);
                setSelectedSlab('')
              });
          } else {
            setSelectedSlab('')
          }
        })
        .catch((error) => {
          console.error("Failed to fetch slab information:", error);
        });

    }
  }, [provinceId, selectedProvince, dispatch]);


  useEffect(() => {
    updateProvinceId(selectedProvince);
  }, [provinces]);

  return (
    <>
      <div className="ml-60 p-4">
        <div className="flex justify-end gap-4">
          {showButton && <div className="flex flex-col gap-2">
            <Button
              variant="primary"
              size="medium"
              type="solid"
              onClick={() => setIsSlabDialogOpen(true)}
            >
              Select Slab
            </Button>
            {selectedSlab ? (
              <p className="text-sm italic text-fg-text flex items-center gap-1">
                <span className="overflow-hidden">{selectedSlab}</span>
                <IconButton icon={<MdCancel />} onClick={handleSlabRemove} style={{background: 'transparent'}} />
              </p>
            ) : (
              <span className="text-sm italic text-fg-text">
                No Slab selected
              </span>
            )}
          </div>}
          <div className="flex flex-col gap-2">
            <Button
              variant="primary"
              size="medium"
              type="solid"
              onClick={() => setIsCountryDialogOpen(true)}
            >
              Select country
            </Button>
            {selectedCountry ? (
              <span className="text-sm italic text-fg-text">
                {selectedCountry}
              </span>
            ) : (
              <span className="text-sm italic text-fg-text">
                No country selected
              </span>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <Button
              variant="primary"
              size="medium"
              type="solid"
              onClick={() => setIsProvinceDialogOpen(true)}
              disabled={!selectedCountry}
            >
              Select province/state
            </Button>
            {selectedProvince ? (
              <span className="text-sm italic text-fg-text">
                {selectedProvince}
              </span>
            ) : (
              <span className="text-sm italic text-fg-text">
                No province/state selected
              </span>
            )}
          </div>
        </div>
      </div>
      {isSlabDialogOpen && <SelectSlabDialogue
        isDialogOpen={isSlabDialogOpen}
        setIsDialogOpen={setIsSlabDialogOpen}
        onSave={handleSlabSelected}
      />}
      {isCountryDialogOpen && (
        <SelectCountryDialog
          isDialogOpen={isCountryDialogOpen}
          setIsDialogOpen={setIsCountryDialogOpen}
          onSave={handleCountrySelected}
        />
      )}
      {isProvinceDialogOpen && (
        <SelectProvinceDialog
          selectedCountry={selectedCountry}
          isDialogOpen={isProvinceDialogOpen}
          setIsDialogOpen={setIsProvinceDialogOpen}
          onSave={handleProvinceSelected}
        />
      )}
    </>
  );
};
