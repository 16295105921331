import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <section className="min-h-screen flex flex-col items-center justify-center bg-gray-100 text-gray-800">
      <div className="bg-white p-8 rounded-lg shadow-md text-center flex flex-col gap-2 items-center justify-center">
        <h1 className="text-4xl font-bold text-fg-text mb-4">Unauthorized</h1>
        <p className="text-lg text-fg-text mb-6">
          You do not have access to the requested page.
        </p>
        <Button onClick={goBack}>
          Go Back
        </Button>
      </div>
    </section>
  );
};

export default Unauthorized;
