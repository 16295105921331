import { FC, useEffect, useRef, useState, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "..";
import { useLazyGetCalculationsQuery } from "../../../redux/rtk/categoryQuery";
import { Link } from "react-router-dom";

interface SearchHistoryProps {
  searchQuery: string;
}

export const SearchTable: FC<SearchHistoryProps> = ({ searchQuery }) => {
  const [getCalculations, { data, isLoading, isFetching }] = useLazyGetCalculationsQuery();
  const observerTarget = useRef(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 20;
  const [filteredData, setFilteredData] = useState<any>([]);

  useEffect(() => {
    if (data && data.results.length) {
      setFilteredData((prev: any) => [...prev, ...data.results]); // Append new data

      const nextPage = data.totalPages > page ? page + 1 : page;
      setHasMore(data.totalPages > page);
      setPage(nextPage);
    }
  }, [data]);

  const obs = (entries: any) => {
    if (entries[0].isIntersecting && !isFetching && hasMore) { // Check if not already fetching
      getCalculations({
        page,
        limit,
        searchQuery
      });
    }
  };

  // Setup intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(obs);

    const currentTarget = observerTarget?.current;
    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      observer.disconnect(); // Proper cleanup to avoid memory leaks
    };
  }, [observerTarget, filteredData]); // Ensure fetchData is included in the dependency array

  useEffect(() => {
    if (searchQuery.length) {
      setPage(1);
      setFilteredData([]);
      getCalculations({
        page: 1,
        limit,
        searchQuery
      });
    }
  }, [searchQuery]);

  return (
    <Table className="border border-fg-text-contrast">
      <TableHeader>
        <TableRow className="!text-base font-medium !text-fg-text-contrast">
          <TableHead className="text-center">S.No</TableHead>
          <TableHead className="text-center">Category</TableHead>
          <TableHead className="text-center">Amount</TableHead>
          <TableHead className="text-center">Suit Valuation</TableHead>
          <TableHead className="text-center">Court Fee</TableHead>
          <TableHead className="text-center">Reference</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {filteredData.length > 0 ? (
          filteredData.map((history: any, index: number) => (
            <TableRow  className="text-fg-text" key={history._id}>
                <TableCell className="text-center"><Link to={`/${history._id}`} className="block p-4 align-middle">{index + 1}</Link></TableCell>
                <TableCell className="text-center"><Link to={`/${history._id}`} className="block p-4 align-middle">{history.categoryName}</Link></TableCell>
                <TableCell className="text-center"><Link to={`/${history._id}`} className="block p-4 align-middle">{history.amount}</Link></TableCell>
                <TableCell className="text-center">
                  <Link to={`/${history._id}`} className="block p-4 align-middle">{history.calculation.result}</Link>
                </TableCell>
                <TableCell className="text-center"><Link to={`/${history._id}`} className="block p-4 align-middle">{history.calculation.courtFeeToBeAffixed}</Link></TableCell>
                <TableCell className="text-center"><Link to={`/${history._id}`} className="block p-4 align-middle">{history.calculation.referenceToSource}</Link></TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={6} className="text-center">
              {isFetching ? 'Loading' : 'No results found'}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      <div ref={observerTarget}></div>
    </Table>
  );
};
