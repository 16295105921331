import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  FormInput,
  BlackLogoWithIcon,
} from "../../../components/ui";
import globalData from "../../../dictionary/global.json";
import { FcGoogle } from "react-icons/fc";
import { User, login } from "../../../redux/slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, store } from "../../../redux/store";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { googleAuth } from "../../../lib/utils/google-auth";

export const LogIn: React.FC = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSigingIn, setIsSigningIn] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const { isAuthenticated, error: authError } = useSelector(
    (state: RootState) => state.auth,
  );

  const togglePasswordVisibility = (e: any) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSigningIn(true);
    const userData: User = {
      email: email,
      password: password,
    };
    if (email.length > 0 && password.length > 0) {
      try {
        dispatch(login(userData));
      } catch (error) {
        console.error("Login failed:", error); // Log error if login fails
      }
    }
  };
  const navigateBack = () => {
    navigate(from, { replace: true });
  };

  useEffect(() => {
    setIsSigningIn(false);
  }, [isAuthenticated, authError]);

  useEffect(() => {
    if (isAuthenticated) navigateBack();
  }, []);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.auth.isAuthenticated) {
        navigateBack();
      }
    });
    return unsubscribe;
  }, [navigate, store]);

  return (
    <section>
      <div className="flex h-screen p-5">
        <div className="w-full">
          <div className="mx-auto flex h-full flex-col items-center gap-12 pt-32 md:w-3/4">
            <Link to="/" className="cursor-pointer">
              <BlackLogoWithIcon />
            </Link>
            <main className="flex w-[25rem] flex-col gap-6">
              <div className="flex flex-col gap-2 text-center">
                <h1 className="text-2xl font-semibold text-fg-text-contrast md:text-3xl">
                  {globalData?.AUTH_SCREENS?.LOG_IN?.WELCOME_TEXT}
                </h1>
                <h2 className="text-fg-text md:text-lg">
                  {globalData?.AUTH_SCREENS?.LOG_IN?.GETTING_STARTED_TEXT}
                </h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-4">
                    <FormInput
                      label={
                        globalData?.AUTH_SCREENS?.LOG_IN?.FORM
                          ?.EMAIL_INPUT_LABEL
                      }
                      placeholder={
                        globalData?.AUTH_SCREENS?.LOG_IN?.FORM
                          ?.EMAIL_INPUT_PLACEHOLDER
                      }
                      onChange={handleEmailChange}
                    />
                    <FormInput
                      label={
                        globalData?.AUTH_SCREENS?.LOG_IN?.FORM
                          ?.PASSWORD_INPUT_LABEL
                      }
                      placeholder={
                        globalData?.AUTH_SCREENS?.LOG_IN?.FORM
                          ?.PASSWORD_INPUT_PLACEHOLDER
                      }
                      onChange={handlePasswordChange}
                      type={isPasswordVisible ? "text" : "password"}
                      isPassword
                      isPasswordVisible={isPasswordVisible}
                      togglePasswordVisibility={togglePasswordVisibility}
                    />
                    <div className="flex w-full justify-between text-sm font-medium">
                      <span className="text-fg-text-contrast">
                        {globalData?.AUTH_SCREENS?.LOG_IN?.ALWAYS_LOGGED_IN}
                      </span>
                      <span className="text-primary-solid">
                        {globalData?.AUTH_SCREENS?.LOG_IN?.FORGOT_PASSWORD_TEXT}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 text-center">
                    <Button
                      variant="primary"
                      type="solid"
                      size="medium"
                      isSubmit={true}
                    >
                      {isSigingIn
                        ? globalData?.AUTH_SCREENS?.LOG_IN?.BUTTONS_TEXT
                            ?.LOGING_IN_BUTTON
                        : globalData?.AUTH_SCREENS?.LOG_IN?.BUTTONS_TEXT
                            ?.LOGIN_BUTTON}
                    </Button>
                    <Divider />
                    <Button
                      variant="gray"
                      type="solid"
                      size="medium"
                      onClick={googleAuth}
                    >
                      <FcGoogle className="text-xl" />
                      <p className="-ml-4 w-full">
                        {
                          globalData?.AUTH_SCREENS?.LOG_IN?.BUTTONS_TEXT
                            ?.GOOGLE_PROVIDER
                        }
                      </p>
                    </Button>
                    {/* <GoogleLoginButton onSuccess={handleGoogleSuccess} onFailure={handleGoogleFailure} /> */}
                    <p className="text-sm text-fg-text-contrast">
                      <span>
                        {globalData?.AUTH_SCREENS?.LOG_IN?.NO_ACCOUNT?.[0]}
                      </span>
                      <Link
                        to="/auth/signup"
                        className="cursor-pointer text-primary-solid underline hover:text-primary-border-hover"
                      >
                        {globalData?.AUTH_SCREENS?.LOG_IN?.NO_ACCOUNT?.[1]}
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            </main>
          </div>
        </div>
        <img
          alt="auth"
          src="/assets/images/auth-image.png"
          className="hidden w-4/5 md:flex"
        />
      </div>
    </section>
  );
};
