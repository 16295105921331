"use client";

import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectItem,
  SelectGroup,
  Switch,
} from "../../ui";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { addProvince, fetchCountries } from "../../../redux/slice/dashboardSlice";

interface SelectProvinceDialogProps {
  selectedCountry: string;
  isDialogOpen: boolean;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  onSave: (province: string) => void;
}


export const SelectProvinceDialog: FC<SelectProvinceDialogProps> = ({
  selectedCountry,
  isDialogOpen,
  setIsDialogOpen,
  onSave,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { countries, loading, error } = useSelector((state: RootState) => state.dashboard);
  const [existingProvince, setExcitingProvince] = useState("");
  const [newProvince, setNewProvince] = useState("");
  const [isAddNewActive, setIsAddNewActive] = useState(false);

  const handleSave = () => {
    const provinceToSave = isAddNewActive ? newProvince : existingProvince;
    console.log("Saving:", provinceToSave);
    if (isAddNewActive) {
      dispatch(addProvince({ newProvince, optedCountry: selectedCountry }));
    }
    onSave(provinceToSave);
    setIsDialogOpen(false);
  };

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogContent className="max-w-3xl">
        <div className="flex flex-col gap-8 px-6 py-7">
          <DialogHeader className="!flex-row gap-2 border-none p-0">
            <DialogTitle className="text-lg font-medium text-fg-text-contrast">
              Select province/state
            </DialogTitle>
          </DialogHeader>
          <div className="flex w-full items-center justify-between">
            <div className="flex w-64 flex-col gap-1">
              <Label
                htmlFor="existingProvince"
                className={
                  isAddNewActive ? "!text-fg-text" : "!text-fg-text-contrast"
                }
              >
                Add existing province/state
              </Label>
              <Select
                value={existingProvince}
                onValueChange={setExcitingProvince}
                disabled={isAddNewActive}
              >
                <SelectTrigger aria-label="Province">
                  <SelectValue placeholder="Select a province/state" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {countries?.find(country => country.name === selectedCountry)?.provinces?.map(province => (
                      <SelectItem key={province.id} value={province.name}>
                        {province.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <Switch
              checked={isAddNewActive}
              onCheckedChange={setIsAddNewActive}
            />
            <div className="flex w-64 flex-col gap-1">
              <Label
                htmlFor="newProvince"
                className={
                  isAddNewActive ? "!text-fg-text-contrast" : "!text-fg-text"
                }
              >
                Add a new province/state
              </Label>
              <Input
                id="newProvince"
                value={newProvince}
                onChange={(e) => setNewProvince(e.target.value)}
                placeholder="Enter a new province/state name"
                className="!h-10 !border-fg-line !text-sm"
                disabled={!isAddNewActive}
              />
            </div>
          </div>
        </div>
        <DialogFooter className="flex items-center px-6 py-4">
          <div className="flex items-center gap-2">
            <DialogClose>
              <Button
                as="div"
                variant="gray"
                size="small"
                type="outline"
                onClick={() => setIsDialogOpen(false)}
              >
                Cancel
              </Button>
            </DialogClose>
            <Button
              as="div"
              variant="primary"
              size="small"
              type="solid"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
