import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { BASE_URL } from '../../lib/utils/url';
import { Country, Province, Slab } from '../../types';

interface dashbaordState {
  countries: Country[];
  slabs: Slab[];
  selectedSlab: Slab | null; // Add selected slab
  selectedCountry: Country | null;
  provinces: Province[];
  selectedProvince: Province | null;
  loading: boolean;
  error: string | null;
}

const initialState: dashbaordState = {
  countries: [],
  slabs: [],
  selectedSlab: null,
  selectedCountry: null,
  selectedProvince: null,
  provinces: [], // we may use provinces inside country
  loading: false,
  error: null,
};

export const fetchCountries = createAsyncThunk<Country[], void, { rejectValue: string }>('dashboard/fetchCountries', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${BASE_URL}/v1/country/get-all-countries`);
    localStorage.setItem('countries', JSON.stringify(response.data))
    return response.data.countries;
  } catch (err: AxiosError | any) {
    if (err instanceof AxiosError) {
      console.error(err);
      if (err.response?.status === 401) {
        return rejectWithValue('UNAUTHORIZED');
      }
    }
    return rejectWithValue(err.message as string);
  }
  // try {
  //   localStorage.setItem('countries', JSON.stringify(dummyCountries)); //hard coded countries
  //   return dummyCountries as Country[];
  // } catch (err:any) {
  //   return rejectWithValue(err);
  // }
});

export const addCountry = createAsyncThunk('dashboard/addCountry', async (country: string) => {
  const response = await axios.post(`${BASE_URL}/v1/country/create`, { name: country });
  return response.data.country;
});

export const addProvince = createAsyncThunk('dashboard/addProvince', async ({ newProvince, optedCountry }: { newProvince: string, optedCountry: string }) => {
  const response = await axios.post(`${BASE_URL}/v1/province/create`, { name: newProvince, optedCountry: optedCountry });
  return response.data.province;
});

export const fetchProvinces = createAsyncThunk('dashboard/fetchProvinces', async (countryId: string) => {
  const response = await axios.get(`${BASE_URL}/v1/province/country-id/${countryId}`);
  return response.data.provinces;
});

export const provinceById = createAsyncThunk('dashboard/provinceById', async (provinceId: string) => {
  const response = await axios.get(`${BASE_URL}/v1/province/province-id/${provinceId}`);
  return response.data.province;
});

// export const addCity = createAsyncThunk('admin/addCity', async (city: City) => {
//   const response = await axios.post(`${BASE_URL}/provinces`, city);
//   return response.data;
// });

//slab
export const AddSlabToProvince = createAsyncThunk<
  { message: string; province: any }, // The expected return type
  { slabId: string; provinceId: string }, // The input parameters type
  { rejectValue: string } // The type for rejectWithValue
>(
  'dashboard/AddSlabToProvince',
  async ({ slabId, provinceId }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/v1/province/${provinceId}/add-slab`, { slabId });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || err.message);
    }
  }
);

export const RemoveSlabFromProvince = createAsyncThunk<
  { message: string; province: any }, // The expected return type
  { provinceId: string }, // The input parameters type
  { rejectValue: string } // The type for rejectWithValue
>(
  'dashboard/RemoveSlabToProvince',
  async ({ provinceId }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/v1/province/${provinceId}/remove-slab`);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || err.message);
    }
  }
);

export const fetchSlabs = createAsyncThunk<Slab[], void, { rejectValue: string }>(
  'dashboard/fetchSlabs',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/v1/slab/get-all-slabs`);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.message as string);
    }
  }
);

export const createSlab = createAsyncThunk<Slab, Slab, { rejectValue: string }>(
  'dashboard/createSlab',
  async (slabData:Slab, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/v1/slab/create`, slabData);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.message as string);
    }
  }
);

export const selectSlab = createAsyncThunk<Slab, string, { rejectValue: string }>(
  'dashboard/selectSlab',
  async (slabId, { rejectWithValue }) => {
    try {
      if (!slabId) {
        throw new Error("Undefined Slab Id");
      }
      const response = await axios.get(`${BASE_URL}/v1/slab/get/${slabId}`);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.message as string);
    }
  }
);
// export const updateSelectedSlab = createAsyncThunk('dashboard/updateSelectedSlab', async (slabId: string) => {
//   return slabId; 
// });
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    selectCountry: (state, action) => {
      state.selectedCountry = action.payload;
      state.provinces = [];
    },
  },
  extraReducers: (builder) => {
     // Handle update selected slab
    //  builder.addCase(updateSelectedSlab.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // });
    // builder.addCase(updateSelectedSlab.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.selectedSlab = action.payload; 
    // });
    // builder.addCase(updateSelectedSlab.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message as string;
    // });
    // Handle fetch slabs
    builder.addCase(fetchSlabs.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSlabs.fulfilled, (state, action) => {
      state.loading = false;
      state.slabs = action.payload;
      
    });
    builder.addCase(fetchSlabs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Handle create slab
    builder.addCase(createSlab.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createSlab.fulfilled, (state, action) => {
      state.loading = false;
      state.slabs.push(action.payload);
    });
    builder.addCase(createSlab.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Handle select slab
    builder.addCase(selectSlab.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectSlab.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedSlab = action.payload; 
    });
    builder.addCase(selectSlab.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(fetchCountries.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.loading = false;
      state.countries = action.payload;
    });
    builder.addCase(fetchCountries.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
    builder.addCase(addCountry.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addCountry.fulfilled, (state, action) => {
      state.loading = false;
      state.countries.push(action.payload);
    });
    builder.addCase(addCountry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
    builder.addCase(fetchProvinces.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchProvinces.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedProvince = action.payload;
    });
    builder.addCase(fetchProvinces.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
    builder.addCase(provinceById.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(provinceById.fulfilled, (state, action) => {
      state.loading = false;
      state.provinces = action.payload;
    });
    builder.addCase(provinceById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
    builder.addCase(addProvince.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addProvince.fulfilled, (state, action) => {
      state.loading = false;
      state.provinces.push(action.payload);
    });
    builder.addCase(addProvince.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
    builder.addCase(AddSlabToProvince.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(AddSlabToProvince.fulfilled, (state) => {
      state.loading = false;
      // refetch this province on frontend
    });
    builder.addCase(AddSlabToProvince.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(RemoveSlabFromProvince.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(RemoveSlabFromProvince.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedProvince = action.payload.province;
    });
    builder.addCase(RemoveSlabFromProvince.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { selectCountry } = dashboardSlice.actions;

export default dashboardSlice.reducer;

