import React, { FC } from "react";
import { Button, CategorySelector } from "../../index";
import * as Dialog from "@radix-ui/react-dialog";
import { RxCross1 } from "react-icons/rx";
import { useMediaQuery } from "../../../../lib/hooks";
import { FaRegCheckCircle } from "react-icons/fa";

interface CategoryContainerProps {
  disabled: boolean;
  categories: { name: string; id: string }[];
  selectedCategory: { name: string; id: string; key?: string; } | null;
  setSelectedCategory: (category: { name: string; id: string; key?: string; }) => void;
  subCategories: { name: string; id: string; key?: string; }[];
  selectedSubCategory: { name: string; id: string; key?: string; } | null;
  setSelectedSubCategory: (
    category: { name: string; id: string; key?: string; } | null,
  ) => void;
  subMinorCategories: { name: string; id: string; key?: string; }[];
  selectedSubMinorCategory: { name: string; id: string; key?: string; } | null;
  setSelectedSubMinorCategory: (
    category: { name: string; id: string; key?: string; } | null,
  ) => void;
}

export const CategoryContainer: FC<CategoryContainerProps> = ({
  disabled,
  categories,
  selectedCategory,
  setSelectedCategory,
  subCategories,
  selectedSubCategory,
  setSelectedSubCategory,
  subMinorCategories,
  selectedSubMinorCategory,
  setSelectedSubMinorCategory,
}) => {
  const { width = 768 } = useMediaQuery();
  const buttonSize = width <= 768 ? "small" : "medium";

  const handleSelectCategory = (
    category: { name: string; id: string } | null,
  ) => {
    if (category) {
      setSelectedCategory(category);
      setSelectedSubCategory(null);
      setSelectedSubMinorCategory(null);
    }
  };

  const handleSelectSubCategory = (
    category: { name: string; id: string } | null,
  ) => {
    setSelectedSubCategory(category);
    setSelectedSubMinorCategory(null);
  };

  const handleSelectSubMinorCategory = (
    category: { name: string; id: string } | null,
  ) => {
    setSelectedSubMinorCategory(category);
  };

  return (
    <>
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <Button
            variant="gray"
            type="outline"
            size={buttonSize}
            className="!h-9 w-full !border-fg-text-contrast !text-[14px] disabled:cursor-none disabled:!border-gray-400 disabled:!text-gray-400 disabled:hover:!bg-transparent md:!h-11 md:!text-lg"
            disabled={disabled}
          >
            Select Category
          </Button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 bg-black bg-opacity-60" />
          <Dialog.Content className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] max-h-[85vh] w-[22rem] translate-x-[-50%] translate-y-[-50%] overflow-y-auto rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none md:w-full md:max-w-5xl">
            <Dialog.Title className="m-0 text-center text-[17px] font-medium">
              Select Details
            </Dialog.Title>
            <Dialog.Description className="mb-5 mt-[10px] text-center text-[15px] leading-normal">
              Select details to make calculations.
            </Dialog.Description>
            <div className="grid gap-4 max-md:grid-rows-3 md:grid-cols-3">
              <CategorySelector
                categories={categories}
                onSelect={handleSelectCategory}
                searchPlaceholder="Select Category"
                selectedCategoryName={selectedCategory?.name || undefined}
                categoryTitle="Major"
              />
              {selectedCategory && (
                <CategorySelector
                  categories={subCategories}
                  onSelect={handleSelectSubCategory}
                  searchPlaceholder="Select Minor Category"
                  selectedCategoryName={selectedSubCategory?.name}
                  categoryTitle="Minor"
                />
              )}
              {selectedSubCategory && (
                <CategorySelector
                  categories={subMinorCategories}
                  onSelect={handleSelectSubMinorCategory}
                  searchPlaceholder="Select Sub Minor Category"
                  selectedCategoryName={selectedSubMinorCategory?.name}
                  categoryTitle="Sub-Minor"
                />
              )}
            </div>
            <div className="mt-[25px] flex justify-end">
              <Dialog.Close asChild>
                <Button
                  variant="primary"
                  type="outline"
                  size="small"
                  disabled={!selectedSubMinorCategory}
                >
                  Next
                </Button>
              </Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <RxCross1 />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
        <div className="mt-2 flex flex-col gap-1">
          {selectedCategory && (
            <div className="flex items-center gap-2">
              <FaRegCheckCircle className="h-4 w-4 flex-shrink-0 text-fg-text-contrast" />
              <p className="text-sm md:text-base">{selectedCategory?.name}</p>
            </div>
          )}
          {selectedSubCategory && (
            <div className="flex gap-2">
              <FaRegCheckCircle className="h-4 w-4 flex-shrink-0 text-fg-text-contrast" />
              <p className="text-sm md:text-base">{selectedSubCategory.name}</p>
            </div>
          )}
          {selectedSubMinorCategory && (
            <div className="flex gap-2">
              <FaRegCheckCircle className="h-4 w-4 flex-shrink-0 text-fg-text-contrast" />
              <p className="text-sm md:text-base">
                {selectedSubMinorCategory.name}
              </p>
            </div>
          )}
        </div>
      </Dialog.Root>
    </>
  );
};
