"use client";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogClose,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    Input,
    Label,
    Select,
    SelectTrigger,
    SelectContent,
    SelectValue,
    SelectItem,
    SelectGroup,
    Switch,
    IconButton,
} from "../../ui";
import { useDispatch, useSelector } from "react-redux";
import { createSlab, fetchSlabs, selectSlab } from "../../../redux/slice/dashboardSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { Slab } from "../../../types";
import { PiMinusCircle, PiPlusCircle } from "react-icons/pi";

interface SelectCountryDialogProps {
    isDialogOpen: boolean;
    setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
    onSave: (slab: Slab) => void;
}

export const SelectSlabDialogue: FC<SelectCountryDialogProps> = ({
    isDialogOpen,
    setIsDialogOpen,
    onSave
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { slabs, selectedSlab } = useSelector((state: RootState) => state.dashboard);
    const [existingSlab, setExistingSlab] = useState("");
    const [isAddNewActive, setIsAddNewActive] = useState(false);
    const [slabError, setSlabError] = useState("");
    const [slabData, setSlabData] = useState<Slab>({
        name: '',
        slabs: [{
            min: 0, max: 0,
            value: 0
        }],
    });

    const handleSave = () => {
        setSlabError("");
        let selectedSlab;
        if (existingSlab) {
            selectedSlab = slabs.find((slab: { name: string; }) => slab.name === existingSlab);
        }
        const slabToSave = isAddNewActive ? slabData : selectedSlab || slabs[0];
        if (isAddNewActive) {
            if (!slabToSave.name) {
                setSlabError("Unique Name Required");
                return;
            }
        }
        let isValid = true;
        for (let i = 0; i < slabToSave.slabs.length; i++) {
            const slab = slabToSave.slabs[i];
            const previousSlab = slabToSave.slabs[i - 1];
            const nextSlab = slabToSave.slabs[i + 1];
            if (i > 0) {
                if (slab.min < previousSlab.max) {
                    setSlabError(`Slab ${i + 1} min value must be greater than equal to the previous slab's max value`);
                    isValid = false;
                    break;
                }
            }
            if (i < slabToSave.slabs.length - 1) {
                if (slab.max > nextSlab.min) {
                    setSlabError(`Slab ${i + 1} max value must be less than equal to the next slab's min value`);
                    isValid = false;
                    break;
                }
            }
        }
        console.log("valid", isValid)
        if (!isValid) {
            return; 
        }
        console.log(slabToSave)
        const cleanedSlabs = slabToSave.slabs.filter(slab => slab.value > 0 && slab.value.toString() !== '');
        if (isAddNewActive) {
            slabToSave.slabs = cleanedSlabs;
            dispatch(createSlab(slabToSave)).then((result: any) => {
                if (result.meta.requestStatus === 'fulfilled') {
                    dispatch(selectSlab(result.payload.slab.id)).unwrap().then((response) => {
                        onSave(response);
                    });
                }
            });
        } else {
            if (slabToSave?.id) {
                dispatch(selectSlab(slabToSave.id)).unwrap().then((response) => {
                    onSave(response);
                }).catch((error) => {
                    console.error('Failed to add slab to province:', error);
                });
            }
        }
        setIsDialogOpen(false);
    };
    

    const handleMaxChange = (index: number, value: string) => {
        setSlabError('');
        const newSlabs = [...slabData.slabs];
        const numericValue = Number(value);
        if (isNaN(numericValue)) return;
        newSlabs[index].max = numericValue;
        if(numericValue<=newSlabs[index].min){
            setSlabError('Max should greater than Min');
        }

        for (let i = index + 1; i < newSlabs.length; i++) {
            newSlabs[i].min = newSlabs[i - 1].max + 1;
            if (newSlabs[i].max <= newSlabs[i].min) {
                newSlabs[i].max = newSlabs[i].min + 1;
            }
        }
        setSlabData({ ...slabData, slabs: newSlabs });
    };

    const handleMinChange =(index: number, value: string)=>{
        setSlabError('');
        const newSlabs = [...slabData.slabs];
        const numericValue = Number(value);
        if (isNaN(numericValue)) return;
        newSlabs[index].min = numericValue;
        if(numericValue>=newSlabs[index].max){
            setSlabError('Min should be less then max');
        }
        if(numericValue<=newSlabs[index-1]?.max){
            setSlabError('Min should be greater then max of previous range');
        }
        setSlabData({ ...slabData, slabs: newSlabs });
    }


    const addRow = (index: number) => {
        const newSlabs = [...slabData.slabs];
        const newRow = { min: newSlabs[index].max + 1, max: newSlabs[index].max + 2, value: 0 };
        newSlabs.splice(index + 1, 0, newRow);
        setSlabData({ ...slabData, slabs: newSlabs });
    };

    const removeRow = (index: number) => {
        if (slabData.slabs.length <= 1) return;
        const newSlabs = [...slabData.slabs];
        newSlabs.splice(index, 1);
        setSlabData({ ...slabData, slabs: newSlabs });
    };

    useEffect(() => {
        dispatch(fetchSlabs());
    }, [dispatch]);

    return (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogContent className="max-w-3xl">
                <div className="flex flex-col gap-8 px-6 py-7">
                    <DialogHeader className="!flex-row gap-2 border-none p-0">
                        <DialogTitle className="text-lg text-center w-full font-medium text-fg-text-contrast">
                            Select Slab
                        </DialogTitle>
                    </DialogHeader>
                    <div className="flex w-full items-center justify-between">
                        <div className="flex w-64 flex-col gap-1">
                            <Label
                                htmlFor="existingSlab"
                                className={
                                    isAddNewActive ? "!text-fg-text" : "!text-fg-text-contrast"
                                }
                            >
                                Add existing Slab
                            </Label>
                            <Select
                                value={existingSlab}
                                onValueChange={setExistingSlab}
                                disabled={isAddNewActive}
                            >
                                <SelectTrigger aria-label="Slab">
                                    <SelectValue placeholder="Select a Slab" />
                                </SelectTrigger>
                                <SelectContent className="max-h-[200px] overflow-y-auto">
                                    <SelectGroup>
                                        {slabs?.map((s) => (
                                            <SelectItem key={s.id} value={s.name!}>
                                                {s.name}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                        <Switch
                            checked={isAddNewActive}
                            onCheckedChange={setIsAddNewActive}
                        />
                        <div className="flex w-64 flex-col gap-1">
                            <Label
                                htmlFor="newSlab"
                                className={
                                    isAddNewActive ? "!text-fg-text-contrast" : "!text-fg-text"
                                }
                            >
                                Add a slab name
                            </Label>
                            <Input
                                value={slabData.name}
                                onChange={(e) => setSlabData({ ...slabData, name: e.target.value })}
                                placeholder="Enter a new slab name"
                                className="!h-10 !border-fg-line !text-sm"
                                disabled={!isAddNewActive}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full items-center">
                    {slabError && (
                        <span className="text-red-500 text-sm mt-1">{slabError}</span>
                    )}
                    {isAddNewActive && slabData.slabs.map((slab, index) => (
                        <div key={index} className="flex flex-row items-center gap-2">
                            <input
                                type="number"
                                placeholder="Min"
                                value={slab.min}
                                onChange={(e) => handleMinChange(index, e.target.value)}
                                className="!h-10 !border-fg-line !text-sm"
                            />
                            <div className="flex flex-col">
                                <input
                                    type="number"
                                    placeholder="Max"
                                    value={slab.max === 0 ? '' : slab.max}
                                    onChange={(e) => handleMaxChange(index, e.target.value)}
                                    className="!h-10 !border-fg-line !text-sm"
                                />
                            </div>
                            <input
                                type="number"
                                placeholder="Value"
                                value={slab.value === 0 ? '' : slab.value}
                                onChange={(e) => {
                                    const newValue = Number(e.target.value);
                                    const newSlabs = [...slabData.slabs];
                                    newSlabs[index].value = newValue;
                                    setSlabData({ ...slabData, slabs: newSlabs });
                                }}
                                className="!h-10 !border-fg-line !text-sm"
                            />
                            <IconButton
                            variant="primary"
                            size="small"
                            type="soft"
                            className="!bg-success-solid text-bg-default"
                            icon={<PiPlusCircle />}
                            onClick={() =>addRow(index)}
                          />
                            <IconButton
                                variant="primary"
                                size="small"
                                type="soft"
                                icon={<PiMinusCircle />}
                                className="!bg-alert-solid text-bg-default"
                                onClick={() => removeRow(index)}
                            />
                        </div>
                    ))}
                </div>
                <DialogFooter className="flex items-center px-6 py-4">
                    <div className="flex items-center gap-2">
                        <DialogClose>
                            <Button
                                as="div"
                                variant="gray"
                                size="small"
                                type="outline"
                                onClick={() => setIsDialogOpen(false)}
                            >
                                Cancel
                            </Button>
                        </DialogClose>
                        <Button
                            as="div"
                            variant="primary"
                            size="small"
                            type="solid"
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
