import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../lib/utils/url';
import { isTokenExpired } from '../../lib/utils/token';

export interface User {
  id?: string;
  role?: string;
  isEmailVerified?: boolean;
  name?: string;
  email: string;
  password?: string;
  profilePicture?: string;
}

interface Token {
  token: string;
  expires: string;
}

interface Tokens {
  access: Token;
  refresh: Token;
}

interface AuthState {
  user: User | null;
  tokens: Tokens | null;
  provider: String;
  isAuthenticated: boolean;
  initialTokenRefeshing: boolean;
  googleProviderLoginStarted: boolean;
  googleProviderLoginFinished: boolean;
  loading: boolean;
  error: string | null;
}

const storedUserData = JSON.parse(localStorage.getItem('user') || '{"user": null, "tokens": null, "isAuthenticated": false, "loading": false, "error": null}');
const tokens = storedUserData?.tokens;
const isAccessTokenExpired = tokens?.access?.expires ? isTokenExpired(tokens?.access?.expires) : true;

const initialState: AuthState = {
  user: storedUserData.user,
  tokens: tokens,
  isAuthenticated: tokens ? !isAccessTokenExpired : false,
  loading: false,
  initialTokenRefeshing: tokens ? isAccessTokenExpired : false,
  googleProviderLoginStarted: false,
  googleProviderLoginFinished: false,
  error: null,
  provider: ""
} satisfies AuthState as AuthState;

export const signup = createAsyncThunk('auth/signup', async (userData: User) => {
  const response = await axios.post(`${BASE_URL}/v1/auth/register`, userData);
  localStorage.setItem('user', JSON.stringify(response.data));
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error("registration failed");
  }
});

export const login = createAsyncThunk('auth/login', async (loginData: User, { rejectWithValue }) => {
  const response = await axios.post(`${BASE_URL}/v1/auth/login`, loginData);
  localStorage.setItem('user', JSON.stringify(response.data));
  if (response.status === 200) {
    return response.data;
  } else {
    return rejectWithValue("login failed");
  }
});

export const refreshTokens = createAsyncThunk('auth/refresh', async (refreshData: Token) => {
  const response = await axios.post(`${BASE_URL}/v1/auth/refresh-tokens`, {
    refreshToken: refreshData.token
  });
  const responseData = response.data;
  return responseData;
});

export const loginWithProvider = createAsyncThunk('auth/loginWithProvider', async (pid: string, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${BASE_URL}/v1/google-auth/login`, {
      pid
    });

    localStorage.setItem('user', JSON.stringify(response.data))
    return response.data;
  } catch (err: any) {
    rejectWithValue(err.message || 'Login with provider failed')
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('user');
      state.user = null;
      state.tokens = null;
      state.isAuthenticated = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signup.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(signup.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.initialTokenRefeshing = false;
      state.user = action.payload.user;
      state.tokens = action.payload.tokens;
      state.provider = action.payload.providerName;
    });
    builder.addCase(signup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
      state.isAuthenticated = false;
      state.initialTokenRefeshing = false;
    });
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.tokens = action.payload.tokens;
      state.provider = action.payload.providerName;
      state.initialTokenRefeshing = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
      state.isAuthenticated = false;
      state.tokens = null;
      state.initialTokenRefeshing = false;
    });
    builder.addCase(loginWithProvider.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.googleProviderLoginStarted = true;
    });
    builder.addCase(loginWithProvider.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.tokens = action.payload.tokens;
      state.provider = action.payload.providerName;
      state.initialTokenRefeshing = false;
      state.googleProviderLoginFinished = true;
      // navigate(window.location.origin);
    });
    builder.addCase(loginWithProvider.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
      state.isAuthenticated = false;
      state.initialTokenRefeshing = false;
      state.tokens = null;
      state.googleProviderLoginFinished = true;
    });
    builder.addCase(refreshTokens.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.tokens = action.payload;
      state.initialTokenRefeshing = false;
      localStorage.setItem('user', JSON.stringify({
        user: state.user,
        tokens: state.tokens,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        error: state.error
      }));
    });
    builder.addCase(refreshTokens.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.initialTokenRefeshing = true;
    });
    builder.addCase(refreshTokens.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
      state.isAuthenticated = false;
      state.tokens = null;
      state.initialTokenRefeshing = false;
    });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
