import { Routes, Route, useLocation } from "react-router-dom";
import { Footer, Navbar } from "./components/layout";
import {
  Home,
  SignUp,
  LogIn,
  Calculate,
  AboutUs,
  Services,
  Contact,
  AdminDashboard,
  SearchHistoryPage
} from "./pages";
import { ProtectedRoute } from "./components/privateRoute";
import { isExcludedRoute } from "./lib/utils/excluded-route";
import { Layout } from "./components/layout/layout";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { refreshTokens } from "./redux/slice/authSlice";
import { useAppDispatch } from "./redux/hooks";
import axios from "axios";
import { store } from "./redux/store";
import PaymentPage from "./pages/payment";

function App() {
  const location = useLocation();
  const { tokens } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (tokens) dispatch(refreshTokens(tokens?.refresh)).unwrap().then(() => setInitialized(true)).catch((e) => setInitialized(true));
    else setInitialized(true);
  }, []);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        // throw new Error("Error")
        const state = store.getState();
        const tokens = state.auth.tokens;
        if (tokens?.access?.token?.length) {
          config.headers["Authorization"] = `Bearer ${tokens?.access?.token}`;
        }
        else {
          const storedUserData = JSON.parse(localStorage.getItem('user') || '{"user": null, "tokens": null, "isAuthenticated": false, "loading": false, "error": null}');
          const tokens = storedUserData?.tokens;
          config.headers["Authorization"] = tokens?.access?.token || "";
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }, []);

  return (
    <>
      {!isExcludedRoute(location.pathname) && <Navbar />}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route element={<ProtectedRoute allowedRoles={["subscribedUser", "admin"]} initialized={initialized} />}>
            <Route path="/"  element={<Calculate key={"parent"} />}>
                <Route path=":id" element={<Calculate key={"child"} />} />
              </Route>
              <Route path="search-history" element={<SearchHistoryPage />} />
              <Route path="payment" element={<PaymentPage />} />
            </Route>
          <Route
            element={
              <ProtectedRoute allowedRoles={["admin"]} initialized={initialized} />
            }
          >
            <Route path="admin" element={<AdminDashboard />}>
              <Route path="courtFee" element={<AdminDashboard />} />
              <Route path="suitValuation" element={<AdminDashboard />} />
              <Route path="court-fee-csv" element={<AdminDashboard />} />
              <Route path="suit-valuation-csv" element={<AdminDashboard />} />
            </Route>
          </Route>

          <Route path="auth/signup" element={<SignUp />} />
          <Route path="auth/login" element={<LogIn />} />
        </Route>
      </Routes>
      {!isExcludedRoute(location.pathname) && <Footer />}
    </>
  );
}

export default App;
